.product-select-cascader{
  .ant-cascader-menu-item-disabled{
    border-bottom: 1px solid #f5f5f5;
  }
  .ant-cascader-menu-item-disabled{
    color: rgba(14, 14, 14, 0.37);
  }
  .selected-product{
    flex-grow: 1;
  }
}
