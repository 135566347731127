.dragable {
    margin-left: 30px;
    .bar{
      width: 20px !important;
      max-width: 20px !important;
      min-width: 20px !important;
      display: none;
      cursor: grab;
      text-align: center;
      vertical-align: middle;
      padding-top: 20px;    
        
    }

    &:hover .bar{
      background-color: azure;
      display: block;
      // width: 14px !important;
      // max-width: 14px !important;
      // min-width: 14px !important;
    }
    &:hover{
      margin-left: 0px;
    }
  }
  
 
  .dragable-small {
    // .bar-small{
    //   width: 14px;
    //   max-width: 14px;
    //   min-width: 14px;
    //   display: none;
    //   cursor: grab;
      
    //   padding-top: 20px;    
        
    // }

    &:hover{
      // background-color: azure;
      // display: block;
      border-color: lightblue;
      border-style: dashed;
      border-width: 1px;
      border-radius: 4px;
    }
  }
  
 