.dashboard-order-component{
  .desc{
    color:#9e9e9e;
    font-size:13px;
  }
}

.edit-order-modal{
  .select-product{
    display: block;
    .selector-button{
      width:100%;
      max-width: 100%;
      flex: 1;
    }
    .selected-product{
      padding-left:15px !important;
      padding-top:15px !important;
      max-width: 100%;
    }
  }
}



.package-group .order-item-component .row1 {
  background: white;
}


.package-group {
  padding: 16px;
  background: #f4f4f4;
  border-radius: 12px;
  margin-bottom: 8px;
  border: 1px solid #eee;
  &__header {
    display: flex;
    justify-content: space-between;
}
&__actions span {
  margin: 4px;
  font-size: .75rem;
  cursor: pointer;
}
.order-item-component .actions {
  display: none;
}
  .row2 .dispatch  {
    display: none;
}
}