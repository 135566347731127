.dashboard-photo-plans{
  .head{
    .title{
      font-weight: 500;
    }
    .import{
      font-size:16px;
      cursor: pointer;
    }
  }
  .upload-icon{
    .fa{
      font-size:55px;
      margin-bottom:15px;
    }
  }
  .images{
    .image-wrapper{
      position: relative;
      overflow: hidden;
      .remove{
        position: absolute;
        right:0;
        top:0;
        background:#fff;
        border-radius: 0 0 0 50%;
        padding: 5px 10px;
        cursor: pointer;
        box-shadow: 0 0 5px #ccc;
        &:hover{
          .fa{color:#ff4d4f;}
        }
    }
      .tagorder{
        position: absolute;
        left:0;
        top:0;
        background:#fff;
        border-radius: 0 0 50% 0;
        padding: 5px 10px;
        cursor:grab ;
        box-shadow: 0 0 5px #ccc;
        &:hover{
          .fa{color:#ff4d4f;}
        }
    }
    .image{
      border-radius: 8px;
      height: 160px;
      width: 250px;
      background-color: #f5f5f5;
      background-size: cover;
      background-position: center;
      display: flex;
      flex-direction: column-reverse;
      overflow: hidden;
      }
      .title{
        display:none;
        background:rgb(55, 125, 255);
        text-align: center;
        color:#fff;
        font-weight: 500;
        padding: 8px;
      }
      &:hover .title{
        display:block;
      }

      
    }

    .sortable-item {
      pointer-events: auto !important;      
    }
    .sortable-item:hover {
      cursor: grab;
    }

    .ghost {
      opacity: .5;
      background: #C8EBFB;
    }
    
  }
  .plan-upload{
    .ant-upload{
      width:100%;
    }
    .ant-input-group-addon:last-child{
      background:#D4DAE3;
      color:#8998A5;
      padding:0 18px;
      font-weight: 500;
    }
  }
}

@media (max-width: 767.98px) {
  .dashboard-photo-plans{
    .head{
      .title{
        font-size:16px;
        font-weight: 500;
      }
      .import{
        font-size:14px;
      }
    }
    .images{
      .image{
        height: 100px;
        .title{
          padding:3px;
          font-size:14px;
        }
      }
    }
  }
}

