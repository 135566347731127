.form-control-integrated.tag-select{
  .ant-select-lg .ant-select-selection--multiple .ant-select-selection__rendered li.ant-select-selection__choice{
    padding-left:0;
    line-height:36px;
    .ant-select-selection__choice__remove{
      right:1px;
      top:-2px;
    }
  }
}
