    /*
    Flaticon icon font: Flaticon
    Creation date: 22/06/2016 15:19
    */

    @font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

    .fi:before{
        display: inline-block;
  font-family: "Flaticon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
    }

    .flaticon-artboard:before { content: "\f100"; }
.flaticon-big-bag-with-handles:before { content: "\f101"; }
.flaticon-big-box:before { content: "\f102"; }
.flaticon-big-brochure:before { content: "\f103"; }
.flaticon-big-clip:before { content: "\f104"; }
.flaticon-big-folder:before { content: "\f105"; }
.flaticon-big-mug:before { content: "\f106"; }
.flaticon-big-paint-roller:before { content: "\f107"; }
.flaticon-billboard-with-text:before { content: "\f108"; }
.flaticon-blank-paper:before { content: "\f109"; }
.flaticon-bottle-of-glue:before { content: "\f10a"; }
.flaticon-brochure-folded:before { content: "\f10b"; }
.flaticon-cd-box:before { content: "\f10c"; }
.flaticon-cello-tape:before { content: "\f10d"; }
.flaticon-chromatic-circle:before { content: "\f10e"; }
.flaticon-circular-sticker:before { content: "\f10f"; }
.flaticon-classic-folder:before { content: "\f110"; }
.flaticon-classic-stappler:before { content: "\f111"; }
.flaticon-closed-book:before { content: "\f112"; }
.flaticon-cmyk-circles:before { content: "\f113"; }
.flaticon-computer-monitor-back-view:before { content: "\f114"; }
.flaticon-continuous-feed-paper:before { content: "\f115"; }
.flaticon-crop-symbol:before { content: "\f116"; }
.flaticon-cube-template:before { content: "\f117"; }
.flaticon-cutter-with-lines:before { content: "\f118"; }
.flaticon-drawing-pens:before { content: "\f119"; }
.flaticon-dropper-with-liquid:before { content: "\f11a"; }
.flaticon-empty-box-open:before { content: "\f11b"; }
.flaticon-envelope-back-side:before { content: "\f11c"; }
.flaticon-fabric-paint:before { content: "\f11d"; }
.flaticon-first-printer:before { content: "\f11e"; }
.flaticon-fountain-pen:before { content: "\f11f"; }
.flaticon-glue-stick:before { content: "\f120"; }
.flaticon-hanging-billboard:before { content: "\f121"; }
.flaticon-inclilned-paint-brush:before { content: "\f122"; }
.flaticon-inclined-book:before { content: "\f123"; }
.flaticon-inclined-eraser:before { content: "\f124"; }
.flaticon-inclined-lead:before { content: "\f125"; }
.flaticon-inclined-marker:before { content: "\f126"; }
.flaticon-inclined-pen:before { content: "\f127"; }
.flaticon-inclined-pencil:before { content: "\f128"; }
.flaticon-inclined-pendrive:before { content: "\f129"; }
.flaticon-ink-cartirdge:before { content: "\f12a"; }
.flaticon-ink-drops:before { content: "\f12b"; }
.flaticon-ink-level:before { content: "\f12c"; }
.flaticon-inkwell:before { content: "\f12d"; }
.flaticon-letter-t-on-rectangle:before { content: "\f12e"; }
.flaticon-long-envelope:before { content: "\f12f"; }
.flaticon-magnifier-len:before { content: "\f130"; }
.flaticon-milk-box:before { content: "\f131"; }
.flaticon-newspaper-folded:before { content: "\f132"; }
.flaticon-notebook-with-rings:before { content: "\f133"; }
.flaticon-notepad-with-text:before { content: "\f134"; }
.flaticon-office-photocopier:before { content: "\f135"; }
.flaticon-office-scanner:before { content: "\f136"; }
.flaticon-old-camera:before { content: "\f137"; }
.flaticon-old-printer:before { content: "\f138"; }
.flaticon-one-pine:before { content: "\f139"; }
.flaticon-open-book:before { content: "\f13a"; }
.flaticon-open-book-1:before { content: "\f13b"; }
.flaticon-paint-can-open:before { content: "\f13c"; }
.flaticon-pantone:before { content: "\f13d"; }
.flaticon-paper-drill:before { content: "\f13e"; }
.flaticon-paper-guillotine:before { content: "\f13f"; }
.flaticon-paper-pad:before { content: "\f140"; }
.flaticon-paper-roll:before { content: "\f141"; }
.flaticon-paper-roll-1:before { content: "\f142"; }
.flaticon-paper-tray:before { content: "\f143"; }
.flaticon-pencil-and-ruler-crossed:before { content: "\f144"; }
.flaticon-pencil-box:before { content: "\f145"; }
.flaticon-pencil-can:before { content: "\f146"; }
.flaticon-pencil-sharpener:before { content: "\f147"; }
.flaticon-press:before { content: "\f148"; }
.flaticon-print-mark:before { content: "\f149"; }
.flaticon-print-mark-1:before { content: "\f14a"; }
.flaticon-printer-without-paper:before { content: "\f14b"; }
.flaticon-printing-document:before { content: "\f14c"; }
.flaticon-printing-text:before { content: "\f14d"; }
.flaticon-protactor-measuring:before { content: "\f14e"; }
.flaticon-recycling-sign:before { content: "\f14f"; }
.flaticon-ring-calendar:before { content: "\f150"; }
.flaticon-road-billboard:before { content: "\f151"; }
.flaticon-rubber-stamp:before { content: "\f152"; }
.flaticon-school-compass:before { content: "\f153"; }
.flaticon-scissors-and-cutout-lines:before { content: "\f154"; }
.flaticon-shopping-paper-bag:before { content: "\f155"; }
.flaticon-size-square:before { content: "\f156"; }
.flaticon-staple-remover:before { content: "\f157"; }
.flaticon-stapler:before { content: "\f158"; }
.flaticon-street-bin:before { content: "\f159"; }
.flaticon-t-shirt-with-square:before { content: "\f15a"; }
.flaticon-tablet-with-pen:before { content: "\f15b"; }
.flaticon-three-pins:before { content: "\f15c"; }
.flaticon-transform-square:before { content: "\f15d"; }
.flaticon-two-crayons:before { content: "\f15e"; }
.flaticon-vector-lines:before { content: "\f15f"; }
.flaticon-vinyl-printer:before { content: "\f160"; }
.flaticon-white-out:before { content: "\f161"; }
.flaticon-white-sheets:before { content: "\f162"; }
.flaticon-wrapped-package:before { content: "\f163"; }
    
    $font-Flaticon-artboard: "\f100";
    $font-Flaticon-big-bag-with-handles: "\f101";
    $font-Flaticon-big-box: "\f102";
    $font-Flaticon-big-brochure: "\f103";
    $font-Flaticon-big-clip: "\f104";
    $font-Flaticon-big-folder: "\f105";
    $font-Flaticon-big-mug: "\f106";
    $font-Flaticon-big-paint-roller: "\f107";
    $font-Flaticon-billboard-with-text: "\f108";
    $font-Flaticon-blank-paper: "\f109";
    $font-Flaticon-bottle-of-glue: "\f10a";
    $font-Flaticon-brochure-folded: "\f10b";
    $font-Flaticon-cd-box: "\f10c";
    $font-Flaticon-cello-tape: "\f10d";
    $font-Flaticon-chromatic-circle: "\f10e";
    $font-Flaticon-circular-sticker: "\f10f";
    $font-Flaticon-classic-folder: "\f110";
    $font-Flaticon-classic-stappler: "\f111";
    $font-Flaticon-closed-book: "\f112";
    $font-Flaticon-cmyk-circles: "\f113";
    $font-Flaticon-computer-monitor-back-view: "\f114";
    $font-Flaticon-continuous-feed-paper: "\f115";
    $font-Flaticon-crop-symbol: "\f116";
    $font-Flaticon-cube-template: "\f117";
    $font-Flaticon-cutter-with-lines: "\f118";
    $font-Flaticon-drawing-pens: "\f119";
    $font-Flaticon-dropper-with-liquid: "\f11a";
    $font-Flaticon-empty-box-open: "\f11b";
    $font-Flaticon-envelope-back-side: "\f11c";
    $font-Flaticon-fabric-paint: "\f11d";
    $font-Flaticon-first-printer: "\f11e";
    $font-Flaticon-fountain-pen: "\f11f";
    $font-Flaticon-glue-stick: "\f120";
    $font-Flaticon-hanging-billboard: "\f121";
    $font-Flaticon-inclilned-paint-brush: "\f122";
    $font-Flaticon-inclined-book: "\f123";
    $font-Flaticon-inclined-eraser: "\f124";
    $font-Flaticon-inclined-lead: "\f125";
    $font-Flaticon-inclined-marker: "\f126";
    $font-Flaticon-inclined-pen: "\f127";
    $font-Flaticon-inclined-pencil: "\f128";
    $font-Flaticon-inclined-pendrive: "\f129";
    $font-Flaticon-ink-cartirdge: "\f12a";
    $font-Flaticon-ink-drops: "\f12b";
    $font-Flaticon-ink-level: "\f12c";
    $font-Flaticon-inkwell: "\f12d";
    $font-Flaticon-letter-t-on-rectangle: "\f12e";
    $font-Flaticon-long-envelope: "\f12f";
    $font-Flaticon-magnifier-len: "\f130";
    $font-Flaticon-milk-box: "\f131";
    $font-Flaticon-newspaper-folded: "\f132";
    $font-Flaticon-notebook-with-rings: "\f133";
    $font-Flaticon-notepad-with-text: "\f134";
    $font-Flaticon-office-photocopier: "\f135";
    $font-Flaticon-office-scanner: "\f136";
    $font-Flaticon-old-camera: "\f137";
    $font-Flaticon-old-printer: "\f138";
    $font-Flaticon-one-pine: "\f139";
    $font-Flaticon-open-book: "\f13a";
    $font-Flaticon-open-book-1: "\f13b";
    $font-Flaticon-paint-can-open: "\f13c";
    $font-Flaticon-pantone: "\f13d";
    $font-Flaticon-paper-drill: "\f13e";
    $font-Flaticon-paper-guillotine: "\f13f";
    $font-Flaticon-paper-pad: "\f140";
    $font-Flaticon-paper-roll: "\f141";
    $font-Flaticon-paper-roll-1: "\f142";
    $font-Flaticon-paper-tray: "\f143";
    $font-Flaticon-pencil-and-ruler-crossed: "\f144";
    $font-Flaticon-pencil-box: "\f145";
    $font-Flaticon-pencil-can: "\f146";
    $font-Flaticon-pencil-sharpener: "\f147";
    $font-Flaticon-press: "\f148";
    $font-Flaticon-print-mark: "\f149";
    $font-Flaticon-print-mark-1: "\f14a";
    $font-Flaticon-printer-without-paper: "\f14b";
    $font-Flaticon-printing-document: "\f14c";
    $font-Flaticon-printing-text: "\f14d";
    $font-Flaticon-protactor-measuring: "\f14e";
    $font-Flaticon-recycling-sign: "\f14f";
    $font-Flaticon-ring-calendar: "\f150";
    $font-Flaticon-road-billboard: "\f151";
    $font-Flaticon-rubber-stamp: "\f152";
    $font-Flaticon-school-compass: "\f153";
    $font-Flaticon-scissors-and-cutout-lines: "\f154";
    $font-Flaticon-shopping-paper-bag: "\f155";
    $font-Flaticon-size-square: "\f156";
    $font-Flaticon-staple-remover: "\f157";
    $font-Flaticon-stapler: "\f158";
    $font-Flaticon-street-bin: "\f159";
    $font-Flaticon-t-shirt-with-square: "\f15a";
    $font-Flaticon-tablet-with-pen: "\f15b";
    $font-Flaticon-three-pins: "\f15c";
    $font-Flaticon-transform-square: "\f15d";
    $font-Flaticon-two-crayons: "\f15e";
    $font-Flaticon-vector-lines: "\f15f";
    $font-Flaticon-vinyl-printer: "\f160";
    $font-Flaticon-white-out: "\f161";
    $font-Flaticon-white-sheets: "\f162";
    $font-Flaticon-wrapped-package: "\f163";