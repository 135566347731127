.dashboard-o-s{
  .item{
    color:#8998A5;
    font-size:15px;
    cursor: pointer;
    white-space: nowrap;
    img{
      filter: brightness(0.65);
    }
    .fi, .fa{
      font-size:30px;
      vertical-align: middle;
      padding-right:10px;
    }
    .fa{
      padding-top:7px;
      padding-bottom:7px;
    }
    &.active{
      position: relative;
      background:#377dff;
      color:#fff;
      img{
        filter: brightness(1);
      }
      &:before{
        font-family: "Font Awesome 5 Free";
        content: '\f058';
        position: absolute;
        font-size:28px;
        right:2px;
        top: -2px;
      }
    }
  }
}
