.prepositional-and-instruction{
  .editable-image{
    position: relative;
    .edit-button{
      position: absolute;
      left:20px;
      top:20px;
    }
  }
}
