.scroller::-webkit-scrollbar-thumb {
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #8998A5;
}

.scroller::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 8px;
  background-color: #E6EAF3;
}
.scroller::-webkit-scrollbar{
  width:8px;
}
