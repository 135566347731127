  @media (min-width: 481px){ .auth0-lock.auth0-lock .auth0-lock-header-bg { background: white !important; } }

  @media (min-width: 481px) {
    .auth0-lock.auth0-lock .auth0-lock-center {
      padding-top: 4px !important;
    }
  }

  .auth0-lock.auth0-lock .auth0-lock-header-logo {
    display: none !important;
  }

  .login-container{
    position: absolute; 
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
}

.maintenance-banner {
  background-color: #d32f2f; /* Red background */
  color: #ffffff; /* White text */
  text-align: center;
  padding: 15px;
  font-family: 'Roboto', sans-serif; /* Elegant modern font */
  font-size: 18px; /* Slightly larger text */
  font-weight: 500; /* Medium weight */
  border-bottom: 2px solid #b71c1c; /* Subtle border for separation */
  position: sticky;
  top: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.maintenance-banner::before {
  content: "⚠️ "; /* Warning icon */
  margin-right: 8px;
}