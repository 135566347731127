.gallery-explorer-component {
  min-width: 60% !important;

  #gallery_explorer_date {
    width: 100%;
  }

  .ant-modal-footer {
    button {
      padding: 10px 30px;
      height: 45px;

      &:first-child {
        border: 0;
      }

      &:last-child {
        font-weight: bold;
      }
    }
  }

  .images {
    > div {
      > div:not(.row) { //loading element
        padding-top: 20px;
        width: 100%;
      }
    }

    .image-container {
      position: relative;

      .image {
        border-radius: 8px;
        height: 100px;
        background-color: #f1f1f1;
        background-size: cover;
        background-position: center;
        display: flex;
        flex-direction: column-reverse;
        overflow: hidden;
        cursor: pointer;

        &.selected {
          &:before {
            position: absolute;
            content: ' ';
            right: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
          }

          &:after {
            position: absolute;
            text-align: center;
            width: 100%;
            top: 30px;
            color: white;
            opacity: 0.3;
            content: '\f00c';
            font: 25px "Font Awesome 5 Free";
            font-weight: 900;
          }
        }

        .title {
          background: rgba(55, 125, 255, 0.5);
          text-align: center;
          color: #fff;
          font-weight: 500;
          padding: 8px;
        }
      }

      .select {
        position: absolute;
        right: 10px;
        top: 10px;
        color: #fff;
      }
    }
  }
}
