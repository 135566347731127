/*------------------------------------
  Slick
------------------------------------*/

.u-slick {
	position: relative;
}

// Disable transform effect
.u-slick--transform-off {
	&.slick-transform-off .slick-track {
		transform: none !important;
	}
}