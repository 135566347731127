.select-artwork-popup{
  min-width: 750px;
  .items{
    .item{
      position: relative;
      background: #eaeaea;
      box-shadow: 0 0 5px #ccc;
      cursor:pointer;
      .selected{
        position: absolute;
        bottom: 0;
        right: 0;
        height:30px;
        width: 100%;
        text-align: center;
        background: #007bff;
        color:#fff;
        font-size: 18px;
      }
    }
  }
}
