.task-add-edit{
  min-width:700px;
  .ant-modal-footer {
    button {
      padding: 10px 30px;
      height: 45px;

      &:first-child {
        border: 0;
      }

      &:last-child {
        font-weight: bold;
      }
    }
  }
}
