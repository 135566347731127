.gallery-explorer-editor-component{
  min-width:60% !important;
  .image{
    width: 100%;
    max-height: 450px;
    text-align: center;
    padding-left:3px;
    img{
      max-width:100%;
      max-height: 450px;
      border-radius:8px;
    }
  }
  .ant-modal-footer{
    button{
      padding:10px 30px;
      height:45px;
      &:first-child{
        border:0;
      }
      &:last-child{
        font-weight: bold;
      }
    }
  }
  .ant-select-selection--multiple{
    .ant-select-selection__choice{
      background:#F8F9FA;
      border:0;
      .ant-select-selection__choice__remove{
        right:3px;
        top:5px;
      }
      .ant-select-selection__choice__content{
        padding:5px;
        color:#748390;
        font-size:13px;
      }
    }
  }
}
