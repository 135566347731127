.SingleFileUpload{
  cursor: pointer;
  display:flex;
  .avatar-upload, .ant-upload-select{width:100%;}
  .ant-input-group-addon{
    color:#8C9BA7 !important;;
  }
  .image{
    width: 47px;
    height: 47px;
    margin-left: 5px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}
