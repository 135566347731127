.ant-form-item-control{line-height:normal !important;}
.form-control-integrated{
  .ant-input {
    height: 49px;
  }
  .ant-picker {
    height: 49px;
  }

  // sp
  .ant-select-selector {
    min-height: 49px !important;
  }

  // sp
  // .ant-select-selection-item{
  //   padding-top: 10px;
  // }

  .ant-select-single.ant-select-show-arrow
   .ant-select-selection-item, 
   .ant-select-single
   .ant-select-show-arrow 
   .ant-select-selection-placeholder{
    padding-top: 7px;    
  }


  .ant-select-multiple.ant-select-lg .ant-select-selection-item {
    height: 40px;  
  }

  .ant-select-selection--single{    
    .ant-select-selection__placeholder{      
      padding-right:5px;
      padding-top: 10px;
    }
  }
  .ant-input-group{
    .ant-input-group-addon{
      .anticon{
        svg{
          margin:0;
        }
      }
    }
  }
  .ant-dropdown-button {
    margin: 0 8px 8px 0;
  }
  .ant-btn{
    &.ant-dropdown-trigger{
      height:48px;
      > .anticon{
        vertical-align: top;
      }
    }
  }
  .ant-input-group-addon{
    padding: 0 17px;
    background-color: transparent;
    span.fa{
      color:#8c98a4;
    }
  }
  .ant-calendar-picker{
    input{padding-left:36px;}
    .ant-calendar-picker-clear, & .ant-calendar-picker-icon{
      right: auto;
      left:12px;
    }
  }
  .ant-form-explain{
    margin-top:5px;
  }
  .form-label{
    font-size: 13px;
    text-transform: unset;
  }
}

.ant-drawer{
  z-index:1002;
}

.ant-modal-content{
  .ant-modal-close-x{
    i{
      position: relative;
      &:after{
        position:absolute;
        right:-4px;
        top:-4px;
        font-family: "Font Awesome 5 Free";
        content: '\f00d';
        color:#707070;
      }
      svg{
        display: none;
      }
    }
  }
  .ant-modal-header{
    padding:20px 31px;
    background:#F8F9FA;
    border-bottom: none;
    .ant-modal-title{
      font-weight: 500;
    }
  }
}

.table-integrated{
  .ant-table-thead > tr {
    background: none;
  }
  .ant-table-column-has-sorters{
    .ant-table-header-column{
      display:block;
      .ant-table-column-sorters{
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
  .ant-pagination-disabled{
    display:none;
  }
}

.separated-radio-group {
  .ant-radio-button-wrapper{
    border-radius:4px;
    margin:0 5px;
    border:0;
    padding:0 30px;
    font-size:12px;
    background:#F8F9FA;
    &::before{
      display:none;
    }
    &:first-child{
      border-radius:4px;
      margin-left:0;
    }
    &:last-child{
      border-radius:4px;
      margin-right:0;
    }
  }
}

.image-preview-modal{
  .ant-modal-close{
    top:-16px;
    right:-17px;
  }
}
.ant-large-input{
  .ant-form-explain{
    margin-top:0;
  }
}

.custom-modal{
  .ant-modal-footer {
    button {
      padding: 10px 30px;
      height: 45px;

      &:first-child {
        border: 0;
      }

      &:last-child {
        font-weight: bold;
      }
    }
  }
}

.avatar-upload{
  width:100%;
  .ant-upload-list-item-name{
    overflow-wrap: break-word;
  }
}

.c-pointer{
  cursor: pointer;
}


.ant-carousel {
  .slick-next {
    &::before {
      content: '' !important;
    }
  }
  .slick-prev { 
    &::before {
      content: '' !important;
    }
  }
}


.form-group-label{
  color:#8c98a4;
  font-size:18px;
  font-weight: 500;
  padding-bottom: 15px;
}


.listpage-head{
  display: flex;
  align-items: center;
  margin-bottom:10px;
  h1{
    font-size:15px;
    font-weight: 500;
    width:100%;
  }
  h3{
    width: 100%;
    font-size: 24px;
  }
}


.btn-ghost-secondary {
  color: #71869d;
  background-color: transparent;
}

.btn-ghost-secondary:hover, .btn-ghost-secondary:focus, .btn-ghost-secondary:active, .btn-ghost-secondary.active {
  color: #71869d;
  background-color: rgba(55, 125, 255, 0.1);
}

.text-cap {
  display: block;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.03125rem;
}

/* Link Underline */
.link-underline {
  color: #8c98a4;
  border-bottom: 0.0625rem dashed #97a4af;
}

.link-underline:hover {
  border-color: #0052ea;
}

.link-underline-toggle-default {
  display: inline-block;
}

.link-underline-toggle-toggled {
  display: none;
}

.link-underline.toggled .link-underline-toggle-default {
  display: none;
}

.link-underline.toggled .link-underline-toggle-toggled {
  display: inline-block;
}

/* Link Underline Light */
.link-underline-light {
  color: rgba(255, 255, 255, 0.7);
  border-bottom: 0.0625rem dashed rgba(255, 255, 255, 0.7);
}

.link-underline-light:hover {
  color: #fff;
  border-color: rgba(255, 255, 255, 0.7);
}

/* Dashed */
.card-dashed {
  border-width: 0;
  min-height: 10rem;
}

.card-dashed:hover {
  border-color: rgba(55, 125, 255, 0.7);
}

.card-dashed:hover .card-dashed-body {
  color: #0052ea;
}

.card-dashed .card-dashed-body {
  color: #8c98a4;
  border: 0.0625rem dashed #d6dbeb;
  border-radius: 0.3125rem;
  transition: 0.3s;
}

.card-body-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-direction: column;
      flex-direction: column;
}


.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0.0625rem solid #e7eaf3;
  border-radius: 0.3125rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-body {
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  min-height: 1px;
  padding: 1.5rem;
}

.card-title {
  margin-bottom: 1.5rem;
}

.card-subtitle {
  margin-top: -0.75rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.5rem;
}

.card-header {
  padding: 1.5rem 1.5rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 0.0625rem solid #e7eaf3;
}

.card-header:first-child {
  border-radius: 0.25rem 0.25rem 0 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 1.5rem 1.5rem;
  background-color: #fff;
  border-top: 0.0625rem solid #e7eaf3;
}

.card-footer:last-child {
  border-radius: 0 0 0.25rem 0.25rem;
}

.card-header-tabs {
  margin-right: -0.75rem;
  margin-bottom: -1.5rem;
  margin-left: -0.75rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.75rem;
  margin-left: -0.75rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img,
.card-img-top,
.card-img-bottom {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    -ms-flex: 1 0 0%;
        flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
  }
  .card-group > .card {
    -ms-flex: 1 0 0%;
        flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 1.5rem;
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
       -moz-column-count: 3;
            column-count: 3;
    -webkit-column-gap: 1.25rem;
       -moz-column-gap: 1.25rem;
            column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.center-container{
  position: absolute; 
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}