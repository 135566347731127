.template-footer{
  font-size:13px;  
  color: #8C98A4;
  padding-top:40px;
  font-weight: 500;

  position: absolute;
  bottom: 0;
  width: 100%;    
}
