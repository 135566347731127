@keyframes goToEnd {
  from {width:1%;}
  to {width:99%;}
}

.sfl{
  max-width: 250px; width:100%; position: absolute; right:50%; top:40%; transform: translate(50%, 0); transition: 0.3s all ease-in-out; box-shadow: 4px 4px 20px #e6e6e6; border-radius:10px; padding:20px; text-align:center;
  background: rgba(255,255,255, 0.8);
  &:hover{transform: translate(50%, -10%); transition: 0.3s all ease-in-out;}
  .loading-wrapper{width: 100%;}
  .loading-wrapper{border:2px solid #7ca9fe; border-radius: 5px;}
  .loading-wrapper #loading-bar{background: #7ca9fe; height:20px; animation-name: goToEnd; animation-duration: 30s;}
  .loading-text{text-align:center; padding:10px 10px 0 10px; color:#b7b7b7; font:12px sans-serif, tahoma, arial;}
}
