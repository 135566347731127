.dashboard-agents-component{
  .agent-item{
    display:flex;
    background:#f8f9fa;
    border:1px solid #f2f3fa;
    border-radius:5px;
    width: 350px;
    
  .sortable-item {
    cursor: grab !important;   
  }
  .sortable-item:hover {
    cursor: grab !important;
  }
    .image{
      background-size:cover;
      background-color: #e2e2e2;
      width:50px;
      height:50px;
      border-radius:50%;
      display:flex;
      justify-content: center;
      align-items: center;
      .fa{
        color:#ccc;
        font-size:30px;
      }
    }
    .name{
      padding-top:10px;
      font-size:12px;
      font-weight: 500;
    }
    .view-detail{
      font-size:12px;
    }
    .remove{
      flex-grow:1;
      text-align:right;
      padding-top:10px;
      font-size:12px;
      cursor:pointer;
    }
    .index{
      background-color: white;
      border: 2px solid white;
      border-radius: 2px;
      z-index: 1;
  
      position: absolute;
      width: 17%;
      height: 29%;
      right: 0px;
      bottom: 0px;
    }
    .bellnumbers {
      font-size:12px;
      background-color:gray;
      align-self: flex-start;

      width:16px;
      line-height: 16px;
      text-align: center;
      color:#fff;
      z-index: 2;        
      border-radius: 4px;    
  }
  
  }
}
