.new-artwork-popup{
  min-width: 700px;
  .icon.fi{
    height: 62px;
    line-height: 65px;
  }
  .icon{
    font-size:60px;
  }
  h2{
    padding-top:13px;
    font-size:16px;
    font-weight: 500;
  }
  h2, .icon{
    color:#377CFF;
  }
  .text{
    font-size:13px;
  }
  .SingleFileUpload{
    padding-top:20px;
    display:block;
    .image{display:none;}
  }
  .notice{
    padding-top:10px;
    text-align:left;
    font-size:13px;
    color:#ccc;
  }
}
