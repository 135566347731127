/*------------------------------------
  SVG
------------------------------------*/

.svg-preloader {
  z-index: -1;
  overflow: hidden;
  transition: all $transition-timing-md ease-in;
}

.svg-preloader * {
  &:not(.u-icon):not(.btn):not(.u-media-player):not(.u-media-player__icon):not(.u-label) {
    opacity: 0;
    transition: opacity $transition-timing-md ease-in;
  }
}
