.user-tag-component{
  display:inline-block;
  background: #F8F9FA;
  border-radius:3px;
  padding-right:5px;  
  font-size:11px;
  color:#748390;
  span{
    margin-right: 5px;
    margin-left: 3px;
  }
}
