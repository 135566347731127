.order-item-component{
  .row1{
    border:1px solid #E6EAF3;
    border-radius:5px 5px 0 5px;
    &.selected{
      border:2px solid #377dff;      
    }
    .left-side{
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-content: center;
      .icon{
        width: 65px;
        height: 65px;
        border-radius: 50%;
        background:#e6eaf4;
        color:#8897a5;
        display:flex;
        justify-content: center;
        align-content: center;
        flex-wrap:wrap;
        i{
          font-size:27px;
        }
      }
    }
    .right-side{
      .content{
        border-left:1px solid #E6EAF3;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: center;
        color:#8897a5;
        .icon{
          display:flex;
          justify-content: center;
          i{
            border:1px solid #E6EAF3;
            border-radius:5px;
            color:#8897a5;
            font-size:50px;
          }
        }
        button{
          font-size:11px;
        }
        .image{
          text-align:center;
          img{
            max-width:60px;
            border-radius:5px;
            border:1px solid #E6EAF3;
            cursor:pointer;
          }
        }
      }
    }
  }

  .title-area{
    display:flex;
    .title{
      font-size:1.2rem;
      font-weight: 500;
    }
    .ids{
      padding-left:10px;
    }
  }
  .actions{
    font-size:12px;
    color: #474747;
    span{
      font-weight: 500;
      cursor:pointer;
      color:#377DFF;
      padding-right:5px;
      &:not(:first-child){
        padding-left:5px;
      }
    }
  }
  .properties{
    font-size:12px;
    .value{
      font-size:1rem;
    }
  }
  .dispatch{
    background: #fff;
    color:#377dff;
    border:1px solid #377dff;
    text-align:center;
    font-weight: 500;
    border-radius:0 0 5px 5px;
    padding:5px 10px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    &.selected{
      background: #377dff;
      color:#fff;
      border:1px solid #377dff;
    }
    &.disabled{
      border:0;
      background: #f1f1f1;
      color: #d0d0d0;
    }
    .hover{
      display: none;
      position: absolute;
      right: 0;
      top: 0;
      background: #8dd0ff;
      color: #fff;
      left: 0;
      padding-top: 5px;
      bottom: 0;
    }
    &:hover .hover{
      display:block;
    }
  }
  .dispatch-package{
    background: #fff;
    color:#377dff;
    border:1px solid #377dff;
    text-align:center;
    font-weight: 500;
    border-radius:0 0 5px 5px;
    padding:5px 10px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    &.selected{
      background: #377dff;
      color:#fff;
      border:1px solid #377dff;
    }
    &.disabled{
      border:0;
      background: gray;
      color: #d0d0d0;
    }
    .hover{
      display: none;
      position: absolute;
      right: 0;
      top: 0;
      background: #8dd0ff;
      color: #fff;
      left: 0;
      padding-top: 5px;
      bottom: 0;
    }
    &:hover .hover{
      display:block;
    }
  }
}

@media (max-width: 768px){
  .order-item-component{
    .row1{
      .right-side{
        .content{
          border:0;
        }
      }
    }
  }
}
